<template>
  <div>
    <Loading v-if="loading" />
    <v-container>
      <v-row class="mt-2">
        <v-col cols="12" class="py-0">
          <h2 class="text-secondary header">ประวัติการใช้งาน</h2>
        </v-col>
      </v-row>
    </v-container>
    <div class="mt-8">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10"
        :page.sync="page"
        hide-default-footer
        class="elevation-1"
      >
        <!-- <template v-slot:item.method="{ item }">
          {{ formatActions(item.method) }}
        </template> -->
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | formatDate }}
        </template>
      </v-data-table>
      <div class="d-flex justify-end mt-5">
        <v-pagination
          v-model="page"
          :total-visible="10"
          :length="pageCount"
          @input="getAllLogs($event)"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
import axios from "../../utils/axios.js";
export default {
  components: {
    Loading,
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Date",
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        { text: "IP", value: "ip" },
        { text: "Username", value: "user.username" },
        { text: "Action", value: "method" },
        { text: "Path", value: "baseUrl" },
        { text: "Description", value: "descriptions" },
      ],
      items: [],
      page: 1,
      pageCount: 1,
    };
  },
  mounted() {
    if (this.info && this.info.profile.id !== process.env.VUE_APP_AUTH_ID) {
      this.$router.push("/");
    }
    this.getAllLogs();
  },
  methods: {
    pagination(event) {
      console.log(event);
    },
    async getAllLogs(page) {
      try {
        this.loading = true;
        this.page = page || 1;
        const {
          data: { data },
        } = await axios.get(`/log?page=${this.page}&limit=10`);
        this.page = data.page;
        this.pageCount = data.totalPages;
        this.items = data.docs;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    formatActions(val) {
      switch (val) {
        case "GET":
          return "ดูข้อมูล";
        case "POST":
          return "เพิ่มข้อมูล";
        case "PATCH":
          return "แก้ไขข้อมูล";
        case "PUT":
          return "แก้ไขข้อมูล";
        case "DELETE":
          return "ลบข้อมูล";
        default:
          return val;
      }
    },
  },
};
</script>
